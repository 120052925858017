.graphics_img{
    margin-top: 0%;
    margin-bottom: 5%;
}

.graphics_img > img{
    width: 80%;
}
span{
    color: greenyellow
}

.App > .text-below-header{
    margin-top: 3%;
}