.copyright-container{
    width: 100%;
    background-color: darkgray;
    justify-content: center;
    align-items: center;
    align-content: center;
}

h4{
    font-weight: lighter;
    margin: 0px;
    font-size: 1.1vw;
    padding: 1%;
}