.App {
  text-align: center;
  display: flex;
  flex-direction:column;
  /* font-size: 1vw; */
  font-family: 'Times New Roman', Times, serif
};

.about{
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
