.About-container{
    font-family: 'Times New Roman', Times, serif;
    display: flex;
    border-radius: 2%;
    flex-direction: column;
    align-self: center;
    font-size: 1.3rem;
    margin: 25px;
    width: 60%;
    padding: 3%;
    background-color: lightblue;
}

ol{
    display: flex;
    flex-direction: column;
    text-align: left;
    list-style: none;
}

ol > li::before{
    content: '►';
    padding-right: 2%;
    /* color: rgb(92, 122, 223); */
    color: lightseagreen;
}

p{
    text-align: left;
    padding-left: 3%;
    padding-right: 3%;
    font-size: 1.1vw;
}

li{
    font-size: 1.1vw;
}

h1{
    font-size: 1.5vw;
}