.Contact-container{
    font-family: 'Times New Roman', Times, serif;
    display: flex;
    border-radius: 2%;
    flex-direction: column;
    align-self: center;
    font-size: 1.3rem;
    margin: 25px;
    width: 60%;
    padding: 3%;
    background-color: lightblue;
}

ul{
    list-style: none;
    text-align: left;
}

ul > .contact-list::before{
        content: '►';
        padding-right: 2%;
        color: lightseagreen;
    }
