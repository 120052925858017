.footer-container{
    display: flex;
    width: 100%;
    background: grey;
    align-items: center;
    justify-content: center;
}

.left-section{
    flex: 1;
    /* margin: -10%; */
}

.left-section > img{
    width: 25%;
    margin-left: -20%;
    cursor: pointer;
}

.center-section{
    flex: 1;
}

.center-section > p{
    font-style: italic;
    font-size: 0.9vw;
}

.right-section {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.right-section > .icons {
    display: flex;
    justify-content: center;
    gap: 3%;
    margin: 3%;
}

.icon-setting{
    width: 2vw;
    cursor: pointer;
}

h3{
    font-size: 1.3vw;
    /* margin: 30%;     */
}

.center-section{
    margin: 2%;
}

