#Header-img{
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
}

.Sub-Header{
    display: flex;
    width: 100%;
    height: 1%;
    position: absolute;
    justify-content: space-between;
    top: 0%;
    left: 0%;
}

.left-Sub-Header{
    flex: 0 auto;
    margin: 1% 2%;
}

.right-Sub-Header{
    flex: 0 1 auto;
    margin: 2% 10%;
}

.right-Sub-Header > ul > li{
    list-style: none;
    cursor: pointer;
    padding: 0px 0px 0px 0px;
    font-size: 1.2vw;
}

.right-Sub-Header > ul{
    margin-top: 0%;
    margin-right: -20%;
}

.right-Sub-Header > ul > li > hr{
   border: none;
   width: 70%;
   background-color:blue;
   height: 2px;
}

.right-Sub-Header > ul{
    display: flex;
    justify-content: space-around;
    gap: 35px;
    padding: 0;
}



.left-Sub-Header > img{
    width: 15vw;
    cursor: pointer;
    height: auto;
}


